<template>
  <!-- 授予访问权限 -->
  <el-card class="card_right_bottom">
    <!-- 标题 -->
    <!-- 授予客户访问权限 -->
    <div class="card_right_bottom_title">{{$t('label.login.access.title')}}</div>
    <!--  要帮助解决支持的问题，您可以授予您的管理员作为您登录和访问您的数据权限。 -->
    <div class="card_right_bottom_title_desc">
      {{$t('label.login.access.text')}}
    </div>
    <!-- 第一个盒子 -->
    <div class="box1">
      <div class="item_space">
        <!-- 我的用户名 -->
        <div class="item_space_title_wrappe">
          {{$t('label.login.access.myusername')}}：{{userName}}
        </div>
        <div class="item_space_body_wrappe">
           <!-- 表单模块 -->
            <div class="model_wrapper">
              <el-table
                :data="tableData"
                border
                style="width: 100%"
                header-row-class-name="header_row_class_name"
              >
                <!-- 授予访问权限 -->
                <el-table-column prop="date" :label="$t('label.login.access.permission')">
                </el-table-column>
                <!-- 访问权限持续时间 -->
                <el-table-column prop="selectOption" :label="$t('label.login.access.duedate')">
                  <!-- eslint-disable-next-line -->
                  <template slot-scope="scope">
                    <!-- 请选择 -->
                    <el-select v-model="date" :placeholder="$t('label.tabpage.pleaseSelectz')">
                      <el-option
                        v-for="item in optionList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 按钮 -->
            <div class="flexBox">
              <!-- 保存 -->
               <el-button type="primary" size="mini" @click="save" >{{ $t("label.chatter.save") }}</el-button
              >
              <!-- 取消 -->
              <el-button size="mini" @click="getAdminAccessSetup">{{ $t("label.emailtocloudcc.button.back") }}</el-button>
            </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { adminAccessSetup ,adminAccessSetupSave} from "@/views/profileSetting/profileSettingApi.js";
export default {
  data() {
    return {  
      userName:localStorage.getItem('username'), // 当前用户名
      date:'', // 当前选中的持续时间
      optionList: [], // 下拉框数据
      tableData: [
        { 
          date: this.$i18n.t('label.login.access.admin'),           //您的公司管理员
          selectOption: this.$i18n.t('label.login.access.nopermission'),     //-无访问权限-
        },
      ],
    };
  },
  created(){
    this.getAdminAccessSetup()
  },
  methods: {
    // 获取权限持续时间
    getAdminAccessSetup(){
      adminAccessSetup().then(res=>{
        if(res.result){
          let data=res.data.adminDateList?res.data.adminDateList:[]
          this.optionList=data.map(item=>{
            
            // 处理展示内容
            let obj={}
            obj.value=item.date
            // 年  月  周  天 
            let time=item.dw==='n'?this.$i18n.t('label.login.access.year'):(item.dw==='y'?this.$i18n.t('label.login.access.month')
            :(item.dw==='z'?this.$i18n.t('label.login.access.quater'):this.$i18n.t('label.chatter.day')))
            // 预计到
            obj.name=`${item.gap}${time} ${this.$i18n.t('label.login.access.yujidao')} (${item.date})`
            // 回显
            if(item.date===res.data.admindate){
              this.date=item.date
            }
            return obj
          })
          // -无访问权限-
          this.optionList.unshift({name:this.$i18n.t('label.login.access.nopermission'),value:'n'})
          // 回显
          this.date=res.data.admindate&&res.data.admindate!=='null'?res.data.admindate:'';
        }
      })
    },
    // 保存权限持续时间
    save() {
      adminAccessSetupSave({adminaccess:this.date}).then(res=>{
        if(res.data.result==='true'){
          this.$message.success(res.data.resultInfo)
        }else{
           this.$message.error(res.resultInfo)
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./style/comment";
::v-deep .el-input{
  width: 110%;
}
.flexBox{
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.item_space_body_wrappe {
  padding: 20px;
}
::v-deep .has-gutter {
  background: #666;
}
.header_row_class_name {
  background-color: #666;
}
</style>